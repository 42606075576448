/* eslint-disable react/no-danger-with-children */
import propTypes from 'prop-types'
import * as Styled from './style'

const TextNotFound = ({ icon, text, widthText, bold = false }) => (
  <Styled.TextNotFound>
    {icon}
    <Styled.Typography
      $width={widthText}
      bold={bold}
      variant="body1"
      dangerouslySetInnerHTML={{ __html: text }}
    />
  </Styled.TextNotFound>
)

TextNotFound.propTypes = {
  icon: propTypes.element,
  text: propTypes.string,
  bold: propTypes.bool
}

export default TextNotFound
