import { ADMIN_ROUTE } from './admin'
import { PROFESSOR_ROUTE } from './professor'
import { STUDENT_ROUTE } from './student'

export const ROUTE = {
  LOGIN: '/login',
  LOGIN_ROOT: '/',
  LOGIN_LEGADO: '/login-legado',
  LOGOUT: '/logout',
  ERROR: '/error',
  NOTIFICATION: 'notifications',
  USER: 'user',
  LOGIN_LEGACY: 'login-legacy',
  AUTH_WEB_LOGIN: '/auth-web-login/:userBoundId/:userType/:refreshToken',
  ...ADMIN_ROUTE,
  ...PROFESSOR_ROUTE,
  ...STUDENT_ROUTE
}
