import React, { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { getUserFromLocalStorage } from 'services/helpers'
import { UNITS } from 'services/constants/unit'
import { Box, Table, TableContainer, Paper } from '@mui/material'
import TableHeader from './TableHeader'
import TableFooter from './TableFooter'

const ActivityTable = ({
  activity,
  studentsAll,
  disableActions,
  entityTitle,
  titleButton,
  startIcon,
  handleEditActivity,
  handleDeleteActivity,
  handleCreateActivity,
  handleScore,
  dropouts,
  classButtonRemove,
  classButtonAdd,
  classButtonNew
}) => {
  const handleDisableActions = (rowActivity) => {
    if (disableActions instanceof Function) {
      return disableActions(rowActivity)
    }

    return disableActions
  }

  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const { unit } = params

  const { isProfessor } = getUserFromLocalStorage()
  const isNovoTech =
    unit?.includes(UNITS.NOVOTEC.name) || unit?.includes(UNITS.NOVOTEC.id)

  const showEditAndDeleteButton = useMemo(
    () => !(isProfessor && (unit?.includes(UNITS.MS.name) || isNovoTech)),
    [isProfessor, unit]
  )

  return (
    <>
      {activity && activity ? (
        <Box sx={{ width: '100%', padding: 0 }}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <TableContainer>
              <Table
                sx={{ '.MuiTableCell-root': { borderBottom: 'none' } }}
                aria-labelledby="tableTitle"
                size="medium"
              >
                <TableHeader
                  classButtonRemove={classButtonRemove}
                  classButtonAdd={classButtonAdd}
                  classButtonNew={classButtonNew}
                  activity={activity}
                  handleEditActivity={handleEditActivity}
                  handleDeleteActivity={handleDeleteActivity}
                  titleButton={titleButton}
                  startIcon={startIcon}
                  handleCreateActivity={handleCreateActivity}
                  dropouts={dropouts}
                  showEditAndDeleteButton={showEditAndDeleteButton}
                />

                {studentsAll &&
                  studentsAll.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`

                    return (
                      <TableFooter
                        index={index}
                        key={labelId}
                        handleScore={handleScore}
                        labelId={labelId}
                        row={row}
                        activity={activity}
                        dropouts={dropouts}
                        disableActions={handleDisableActions}
                      />
                    )
                  })}
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      ) : (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <p>Ainda não há {entityTitle} registradas</p>
        </Box>
      )}
    </>
  )
}

export default ActivityTable
