const cardsDownloadsTootips = ({ id, setOpenModalCards }) => [
  {
    path: `acesso/aluno_fichaindividual_CT10.php?id_turma=${id}&matricula=0`,
    label: 'Ficha Individual',
    className: 'academico_btn_baixa_fichasMatricula',
    hidden: true
  },
  {
    path: `acesso/aluno_boletim_pdf1.php?id_turma=${id}&matricula=0&tipoArquivo=pdf`,
    label: 'Boletins (PDF)',
    className: 'academico_btn_baixa_boletinsPdf',
    classId: id
  },
  {
    path: `acesso/aluno_boletim_pdf1.php?id_turma=${id}&matricula=0&tipoArquivo=excel`,
    label: 'Boletins (XLS)',
    className: 'academico_btn_baixa_boletinsXml',
    classId: id
  },
  {
    label: 'Carteirinhas',
    className: 'academico_btn_baixa_carteirinhas',
    handleClick: () => setOpenModalCards(true),
    isModalCards: true,
    hidden: true
  },
  {
    path: `acesso/turma_resultados_final.php?idT=${id}&sitT=3&tipT=1`,
    label: 'Resultado Simplificado',
    className: 'academico_btn_baixa_resultadoSimplificado',
    hidden: true
  },
  {
    path: `acesso/turma_lista_assinatura_pdf.php?id_turma=${id}&modeloL=1&cpfL=1&telefoneL=1&emailL=1&poloL=1&tipT=1`,
    label: 'Lista de Assinatura',
    className: 'academico_btn_baixa_listaAssinatura',
    hidden: true
  },
  {
    path: `acesso/turma_lista_etiquetas.php?id_turma=${id}`,
    label: 'Etiquetas de Alunos',
    className: 'academico_btn_baixa_etiquetasAlunos',
    hidden: true
  }
]

const CARD_TYPE = {
  FREQUENCY_DIARY: 'Diário de Frequencia',
  NOTE_DIARY: 'Diário de Notas',
  SCHOOL_REPORT_PDF: 'Boletins (PDF)',
  SCHOOL_REPORT_XLSX: 'Boletins (XLS)'
}

const cardsDownloadInline = ({ id, idDisc, idStep, setOpenModalCoverPage }) => [
  {
    path: `acesso/imprimir_diario_frequencia_modulo.php?turma_disc=${idDisc}&id_turma=${id}&id_etapa=${idStep}`,
    label: CARD_TYPE.FREQUENCY_DIARY,
    className: 'academico_btn_baixa_diarioFrequencia',
    classId: id,
    classDisciplineId: idDisc
  },
  {
    path: `acesso/imprimir_diario_conteudo.php?turma_disc=${idDisc}&id_turma=${id}&id_etapa=${idStep}`,
    label: 'Diario de Conteúdo',
    className: 'academico_btn_baixa_diarioConteudo',
    classId: id,
    classDisciplineId: idDisc
  },
  {
    path: `acesso/imprimir_diario_notas.php?id_turma=${id}&turma_disc=${idDisc}&id_etapa=${idStep}&modeloL=pdf`,
    label: CARD_TYPE.NOTE_DIARY,
    className: 'academico_btn_baixa_diarioNotas',
    classId: id,
    classDisciplineId: idDisc
  },
  {
    label: 'Folha de rosto',
    className: 'academico_btn_baixa_folhaRosto',
    handleClick: () => setOpenModalCoverPage(true),
    isModalCoverPage: true,
    classId: id,
    classDisciplineId: idDisc
  }
]

const cardTitles = ({
  id,
  idDisc,
  idStep,
  openModalExportFilesInline,
  setOpenModalCards,
  setOpenModalCoverPage
}) => {
  if (openModalExportFilesInline) {
    return cardsDownloadInline({ id, idDisc, idStep, setOpenModalCoverPage })
  }

  return cardsDownloadsTootips({ id, idDisc, idStep, setOpenModalCards })
}

export { cardTitles, CARD_TYPE }
