import * as yup from 'yup'
import { isValidYear } from 'services/helpers'

const requiredMessage = 'Este campo é obrigatório'

const schema = yup.object().shape({
  course: yup.string().when('isCnpj', (isCnpj, currentScheme) => {
    if (isCnpj) {
      return currentScheme
    }

    return currentScheme.required(requiredMessage)
  }),
  typeOfPayment: yup.string().required(requiredMessage),
  account: yup
    .string()
    .when('typeOfPayment', (typeOfPayment, currentScheme) => {
      if (typeOfPayment === 'Boleto') {
        return currentScheme.required(requiredMessage)
      }

      return currentScheme
    }),
  documentDate: yup
    .string()
    .nullable()
    .nullable()
    .required(requiredMessage)
    .test(
      'isValidDate',
      'A data para ser válida o ano precisa conter os 4 dígitos',
      isValidYear
    ),
  dueDate: yup
    .string()
    .nullable()
    .required(requiredMessage)
    .test(
      'isValidDate',
      'A data para ser válida o ano precisa conter os 4 dígitos',
      isValidYear
    ),
  installmentNumber: yup.string().required(requiredMessage),
  installmentsAmount: yup.string().required(requiredMessage),
  installmentValue: yup.string().required(requiredMessage),
  description: yup.string().required(requiredMessage),
  unit: yup.string().required(requiredMessage),
  costCenter: yup.string().required(requiredMessage),
  type: yup.string().required(requiredMessage),
  subcategory: yup.string().required(requiredMessage),
  nature: yup.string().required(requiredMessage),
  amount: yup.string().required(requiredMessage)
})

export default schema
