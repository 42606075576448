import { utilsPDF } from 'components/templates/PDF/shared/utils'
import { UNITS } from 'services/constants'
import { formatNumberToScore } from 'services/helpers'

const { addAsteristic } = utilsPDF

const handleGrade = (
  isMS,
  grade,
  disciplineHasAlreadyStarted,
  minimumGrade
) => {
  if (isMS && grade.finalGrade) {
    const finalGrade = grade.activitiesGrade + grade.assessmentGrade
    const score =
      finalGrade > grade.recoveryGrade ? finalGrade : grade.recoveryGrade
    const formattedScore = formatNumberToScore(score)

    return score < minimumGrade ? addAsteristic(formattedScore) : formattedScore
  }

  if (grade.finalGrade) {
    const score = formatNumberToScore(grade.finalGrade)
    return grade.finalGrade < minimumGrade ? addAsteristic(score) : score
  }

  return disciplineHasAlreadyStarted ? addAsteristic('0,0') : '-'
}

const buildTableBody = (unit, students, indexChunk, minimumGrade) => {
  const isMS = unit === UNITS.MS.name

  return students.map((studentEl, index) => {
    const { student, gradeDetails } = studentEl
    return [
      { text: index + 1, style: ['tableBodyCell'] },
      { text: student.name, style: ['tableBodyCell'] },
      ...gradeDetails[indexChunk]
        .map((subject) => [
          {
            text: handleGrade(
              isMS,
              subject.grade,
              subject.disciplineHasAlreadyStarted,
              minimumGrade
            ),
            style: ['tableBodyCell'],
            alignment: 'center'
          },
          {
            text: `${Math.floor(subject.frequency.absenceInPercentage)}%`,
            style: ['tableBodyCell'],
            alignment: 'center'
          }
        ])
        .flat()
    ]
  })
}
export default buildTableBody
