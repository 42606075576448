import { IsEmpty } from 'services/helpers'

/* eslint-disable camelcase */
const deseableNotConfirm = (values, fields) => {
  const verifyFields = fields?.map(
    ({
      discipline,
      code,
      avaliation_type,
      module_number,
      workload_hours,
      workload_minutes
    }) =>
      discipline !== '' &&
      code !== '' &&
      avaliation_type !== '' &&
      module_number !== '' &&
      workload_hours !== '' &&
      workload_minutes !== ''
  )

  return (
    !values.level ||
    !values.course ||
    !values.yearGrid ||
    IsEmpty(fields) ||
    verifyFields?.includes(false)
  )
}

const parserSubjects = (response) =>
  response?.reduce((acc, cur) => {
    const {
      active,
      discipline,
      workload_hours,
      workload_minutes,
      discipline_code,
      avaliation_type,
      module_number,
      reference_code
    } = cur

    const newItem = {
      discipline,
      avaliation_type,
      workload_hours,
      workload_minutes,
      code: discipline_code,
      module_number: Number(module_number),
      active: Boolean(active),
      reference_code
    }
    return [...acc, newItem]
  }, [])

const parserSubjectsTwo = (response, subject) =>
  response?.reduce((acc, cur) => {
    const {
      active,
      id,
      code,
      discipline,
      workload_hours,
      workload_minutes,
      avaliation_type,
      module_number,
      reference_code
    } = cur

    const disciplineValue = subject.find((item) => item.code === code)

    const newItem = {
      ...(id && { id }),
      discipline: disciplineValue?.discipline || discipline,
      code,
      avaliation_type,
      workload_hours: Number(workload_hours),
      workload_minutes: Number(workload_minutes),
      module_number: Number(module_number),
      active: Boolean(active),
      reference_code
    }
    return [...acc, newItem]
  }, [])

const parseCBOs = (cbos) => cbos.map(({ id, cbo_id }) => cbo_id ?? id)

const payload = ({ values, fields, subject, curriculumId, hasYearGrid }) => {
  const result = {
    resolution: values?.resolution,
    complement: values?.complement,
    disciplines: parserSubjectsTwo(fields, subject),
    cbo_ids: parseCBOs(values.cbo)
  }

  if (hasYearGrid) {
    result.year_grid = values?.yearGrid
    result.level = values?.level
    result.course = values?.course
  }

  if (curriculumId) {
    result.grid_curriculum_id = curriculumId
  }

  return result
}

export { payload, parserSubjects, parserSubjectsTwo, deseableNotConfirm }
