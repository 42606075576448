import React, { useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import useAuth from 'services/hooks/useAuth'
import { CardActions, CardContent } from '@mui/material'
import useLogin from 'services/hooks/useLogin'
import * as Styled from './style'

const AuthWebLogin = () => {
  const { token, userBoundId, userType, getValidToken, hasError } = useAuth()

  const { handleLogin, serverMessage } = useLogin()

  useEffect(() => {
    getValidToken()
    if (token) {
      handleLogin({
        refreshToken: token,
        userBoundId: Number(userBoundId),
        userType: Number(userType)
      })
    }
  }, [token])

  return (
    <Styled.FormWrapper>
      <Styled.Card>
        {serverMessage || hasError ? (
          <>
            <CardContent>
              <Styled.Logotype width="160" />
              <Styled.Message>{serverMessage}</Styled.Message>
            </CardContent>
            <CardActions>
              <a href={`${process.env.REACT_APP_API_AUTH_WEB}/login`}>
                Clique aqui para voltar ao login
              </a>
            </CardActions>
          </>
        ) : (
          <>
            <CardContent>
              <Styled.Logotype width="160" />
            </CardContent>
            <Styled.CardCircularProgress>
              <Styled.Message>Aguarde carregando</Styled.Message>
              <CircularProgress />
            </Styled.CardCircularProgress>
          </>
        )}
      </Styled.Card>
    </Styled.FormWrapper>
  )
}

export default AuthWebLogin
