import { Chip } from '@mui/material'

export default function FieldTagsList(value, getTagProps, parent) {
  return value.map((option, index) => {
    const tagProps = getTagProps({ index })

    return (
      <Chip
        variant="outlined"
        label={parent.getOptionLabel(option)}
        {...tagProps}
      />
    )
  })
}
