import { FormControl, Grid, InputAdornment, Typography } from '@mui/material'
import { Clear } from '@mui/icons-material'
import { ButtonFooterV2, Select, TextField } from 'components/molecules'
import { DatePicker } from 'components/atoms'
import { typeMask } from 'services/helpers/mask'
import { currencyMaskWithoutPrefix } from 'services/helpers'
import TAG_MANAGER from 'tagManager'
import CostCenter from '../../../BankBilling/components/CostCenter'
import * as Styled from './style'
import { paymentTypesOptions } from '../../contants'

const Form = ({
  setSnack,
  openModalConfirm,
  errors,
  isValid,
  touched,
  handleSubmit,
  setFieldValue,
  values,
  setValues,
  listNature,
  listCourses,
  listUnits,
  listCostCenter,
  accountsWithoutCaixa
}) => (
  <Styled.FormBox onSubmit={handleSubmit} noValidate autoComplete="off">
    <Grid container spacing={2} marginTop="20px">
      <Grid item xs={12}>
        <Typography variant="h5" component="h5">
          Dados do título
        </Typography>
      </Grid>

      {!values.isCnpj && (
        <Grid item xs={12}>
          <Select
            id="course"
            name="course"
            value={values?.course || ''}
            label="Selecione o curso"
            required
            optionsList={listCourses}
            onChange={({ target }) => setFieldValue('course', target.value)}
            helperText={touched.course && errors.course}
            error={Boolean(touched.course && errors.course)}
          />
        </Grid>
      )}

      <Grid item xs={3}>
        <Select
          fullWidth
          id="typeOfPayment"
          name="typeOfPayment"
          label="Tipo de pagamento"
          required
          value={values.typeOfPayment ?? null}
          onChange={({ target }) => {
            setFieldValue('typeOfPayment', target.value)
            setFieldValue('account', '')
          }}
          optionsList={paymentTypesOptions}
          helperText={touched.typeOfPayment && errors.typeOfPayment}
          error={Boolean(touched.typeOfPayment && errors.typeOfPayment)}
        />
      </Grid>

      <Grid item xs={6}>
        <Select
          id="account"
          name="account"
          value={values?.account || ''}
          label="Selecione a conta"
          required={values.typeOfPayment !== 'RECURRENCE'}
          disabled={values.typeOfPayment === 'RECURRENCE'}
          optionsList={accountsWithoutCaixa}
          onChange={({ target }) => setFieldValue('account', target.value)}
          helperText={touched.account && errors.account}
          error={Boolean(touched.account && errors.account)}
        />
      </Grid>

      <Grid item xs={3}>
        <DatePicker
          fullWidth
          id="documentDate"
          name="documentDate"
          label="Data do Documento"
          value={values.documentDate ?? null}
          required
          onChange={(newValue) => {
            setFieldValue('documentDate', newValue)
          }}
          helperText={touched.documentDate && errors.documentDate}
          error={Boolean(touched.documentDate && errors.documentDate)}
        />
      </Grid>

      <Grid item xs={3}>
        <DatePicker
          fullWidth
          id="dueDate"
          label="Vencimento da 1ª parcela"
          value={values.dueDate ?? null}
          required
          onChange={(newValue) => setFieldValue('dueDate', newValue)}
          helperText={touched.dueDate && errors.dueDate}
          error={Boolean(touched.dueDate && errors.dueDate)}
          minDate={new Date()}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          fullWidth
          name="installmentNumber"
          id="installmentNumber"
          label="Número da Parcela"
          value={values?.installmentNumber ?? ''}
          required
          helperText={touched.installmentNumber && errors.installmentNumber}
          error={Boolean(touched.installmentNumber && errors.installmentNumber)}
          onChange={(e) => {
            setFieldValue(
              'installmentNumber',
              typeMask('NUMBER', e.target.value)
            )
          }}
          isMargin="0"
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          className={TAG_MANAGER.financial_input_inform_title}
          fullWidth
          name="installmentsAmount"
          id="installmentsAmount"
          label="Quantidade de Parcelas"
          value={values?.installmentsAmount ?? ''}
          required
          helperText={touched.installmentsAmount && errors.installmentsAmount}
          error={Boolean(
            touched.installmentsAmount && errors.installmentsAmount
          )}
          onChange={(e) => {
            setFieldValue(
              'installmentsAmount',
              typeMask('NUMBER', e.target.value)
            )
          }}
          isMargin="0"
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          fullWidth
          name="installmentValue"
          id="installmentValue"
          label="Valor da Parcela"
          required
          helperText={touched.installmentValue && errors.installmentValue}
          error={Boolean(touched.installmentValue && errors.installmentValue)}
          onChange={(e) =>
            setFieldValue(
              'installmentValue',
              currencyMaskWithoutPrefix(e.target.value)
            )
          }
          InputProps={{
            value: values.installmentValue || '',
            endAdornment: <InputAdornment position="end">R$</InputAdornment>
          }}
          isMargin="0"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          name="description"
          id="description"
          label="Descrição"
          required
          multiline
          rows={3}
          rowsMax={10}
          value={values?.description ?? ''}
          helperText={touched.description && errors.description}
          error={Boolean(touched.description && errors.description)}
          onChange={(e) => {
            setFieldValue('description', e.target.value)
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          name="additionalDescription"
          id="additionalDescription"
          label="Descrição Adicional (Opcional)"
          multiline
          rows={3}
          rowsMax={10}
          inputProps={{
            maxLength: 4000
          }}
          value={values?.additionalDescription ?? ''}
          helperText={
            touched.additionalDescription && errors.additionalDescription
          }
          error={Boolean(
            touched.additionalDescription && errors.additionalDescription
          )}
          onChange={(e) => {
            setFieldValue('additionalDescription', e.target.value)
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" component="h5">
          Centro de custo
        </Typography>
      </Grid>

      {values?.hasCostCenter ? (
        <Grid item xs={12}>
          <FormControl fullWidth>
            <CostCenter
              values={values}
              touched={touched}
              errors={errors}
              setFieldValue={setFieldValue}
              setValues={setValues}
              listNature={listNature}
              listUnits={listUnits}
              listCostCenter={listCostCenter}
              confirmAction={() => setFieldValue('hasCostCenter', false)}
              setSnack={setSnack}
            />
          </FormControl>
        </Grid>
      ) : (
        <Grid item xs={12} display="flex" justifyContent="center">
          <Styled.AddIcon
            onClick={() => setFieldValue('hasCostCenter', true)}
          />
        </Grid>
      )}

      <Styled.GroupButtons item xs={12}>
        <ButtonFooterV2
          type="submit"
          disabled={!isValid}
          startIcon={<Clear />}
          labelClose="Cancelar"
          onClickClose={() => openModalConfirm()}
          labelConfirm="Salvar"
        />
      </Styled.GroupButtons>
    </Grid>
  </Styled.FormBox>
)

export default Form
