const TYPES_OF_PAYMENT = Object.freeze({
  DEFAULT: { value: 'DEFAULT', label: 'Boleto' },
  RECURRENCE: { value: 'RECURRENCE', label: 'Recorrência' },
  RECURRENCE_MIGRATION: {
    value: 'RECURRENCE_MIGRATION',
    label: 'Recorrência de migração'
  }
})

export { TYPES_OF_PAYMENT }
