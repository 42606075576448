import { httpGet, httpPost, httpPut } from 'services/api/http'
import { ENDPOINT } from 'services/constants'
import { convertToParams } from 'services/helpers'

export const getCurriculum = async (params) => {
  const response = await httpGet(`${ENDPOINT.CURRICULUM.CRUD}`, {
    params
  })

  return response
}

export const getCurriculumById = async (id) => {
  const response = await httpGet(`${ENDPOINT.CURRICULUM.CRUD}/${id}`)

  return response
}

export const getCurriculumSubject = async () => {
  const response = await httpGet(`${ENDPOINT.CURRICULUM.SUBJECT}?limit=2000`)

  return response
}

export const getCurriculumCourses = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_MANAGEMENT_CURRICULUM_LEVEL_COURSES}?level_name=${params}`
  )

  return response
}

export const getCurriculumModule = async () => {
  const response = await httpGet(
    `${ENDPOINT.CURRICULUM.MODULE}?${convertToParams()}`
  )

  return response
}

export const createNewCurriculum = async (payload) => {
  const response = await httpPost(`${ENDPOINT.CURRICULUM.CRUD}`, {
    ...payload
  })

  return response
}

export const copyCurriculum = async (id, payload) => {
  const response = await httpPost(`${ENDPOINT.CURRICULUM.CRUD}/${id}/copy`, {
    ...payload
  })

  return response
}

export const inactivateCurriculum = async (id) => {
  const response = await httpPut(`${ENDPOINT.CURRICULUM.CRUD}/${id}/inactivate`)

  return response
}

export const activateCurriculum = async (id) => {
  const response = await httpPut(`${ENDPOINT.CURRICULUM.CRUD}/${id}/activate`)

  return response
}

export const updateCurriculum = async (payload, idCurriculum) => {
  const response = await httpPut(
    `${ENDPOINT.CURRICULUM.CRUD}/${idCurriculum}`,
    {
      ...payload
    }
  )

  return response
}

export const getCBOs = async (params) => {
  const response = await httpGet(`${ENDPOINT.CURRICULUM.CRUD}/cbo/paginate`, {
    params
  })

  return response
}
