import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { ArrowBack } from '@mui/icons-material'
import { ModalConfirm } from 'components/molecules'
import { TemplateNewBankBilling as Template } from 'components/templates/Admin'
import { convertToParams, getUserFromLocalStorage } from 'services/helpers'
import { ROUTE } from 'services/constants'
import { useMakeService, useSnack } from 'services/hooks'
import Form from './components/Form'
import {
  breadcrumbsItems,
  initialValues,
  textMessageModalConfirm,
  textTitleModalConfirm
} from './contants'
import * as Styled from './style'
import { createTitle, initialAllServices } from './services'
import schema from './schema'

const NewBankBilling = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const snackProps = useSnack()
  const [open, setOpen] = useState(false)
  const [formValues, __] = useState(initialValues(params))
  const [listNature, setListNature] = useState([])
  const [listCostCenter, setListCostCenter] = useState([])
  const [listUnits, setListUnits] = useState([])
  const [listCourses, setListCourses] = useState([])

  const companyIdJoy = 87
  const { company_id: companyId } = getUserFromLocalStorage()
  const isCompanyJoy = companyId === companyIdJoy

  const paramsMakeService = {
    isAccount: true,
    setSnack: snackProps.setSnack
  }
  const { accounts } = useMakeService(paramsMakeService)

  const accountsWithoutCaixa = isCompanyJoy
    ? accounts
    : accounts?.filter((item) => !item?.label?.endsWith('- Caixa'))

  const redirectToFinancialBreakdown = () =>
    navigate(
      `${ROUTE.ADMIN_FINANCIAL_BREAKDOWN}?${convertToParams({
        register: params?.register,
        name: params?.name
      })}`
    )

  const openModalConfirm = () => setOpen(true)

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: formValues,
    onSubmit: (values) => createTitle({ values, params, navigate }),
    validationSchema: schema
  })

  useEffect(() => {
    initialAllServices({
      registerId: params?.register,
      setListCourses,
      setListUnits,
      setListNature,
      setListCostCenter
    })
  }, [])

  return (
    <Template
      snackProps={snackProps}
      form={
        <Form
          isValid={formik.isValid}
          errors={formik.errors}
          touched={formik.touched}
          handleSubmit={formik.handleSubmit}
          setFieldValue={formik.setFieldValue}
          values={formik.values}
          setValues={formik.setValues}
          setSnack={snackProps.setSnack}
          openModalConfirm={openModalConfirm}
          listNature={listNature}
          listCourses={listCourses}
          listUnits={listUnits}
          listCostCenter={listCostCenter}
          accountsWithoutCaixa={accountsWithoutCaixa}
        />
      }
      classNameHeader="goBack"
      labelButton="Voltar"
      breadcrumbsItems={breadcrumbsItems}
      startIconButton={<ArrowBack />}
      buttonAction={openModalConfirm}
      headerContent={
        <Styled.BoxLink>
          <Styled.Link
            to={`${ROUTE.ADMIN_FINANCIAL_BREAKDOWN}?${convertToParams({
              register: params.register,
              name: params.name
            })}`}
          >
            {`${params.register} - ${params.name}`}
          </Styled.Link>
        </Styled.BoxLink>
      }
      modal={
        <ModalConfirm
          open={open}
          handleClose={() => setOpen(false)}
          textButtonNotConfirm="Não"
          textButtonConfirm="Sim"
          icon={<Styled.IconWarningAmber />}
          title={textTitleModalConfirm}
          message={textMessageModalConfirm}
          onClickNotConfirm={() => setOpen(false)}
          onClickConfirm={() => redirectToFinancialBreakdown()}
        />
      }
    />
  )
}

export default NewBankBilling
