import { getMoneyFormatToNumber } from 'services/helpers'

export const convertedValuesToNewBankBilling = ({
  registerId,
  account,
  documentDate,
  typeOfPayment,
  dueDate,
  installmentNumber,
  installmentsAmount,
  installmentValue,
  description,
  additionalDescription,
  amount,
  costCenter,
  nature,
  subcategory,
  type,
  unit,
  checked,
  course,
  isCnpj
}) => {
  const payload = {
    register: Number(registerId),
    account,
    document_date: documentDate,
    type_of_payment: typeOfPayment,
    due_date: dueDate,
    installment_number: Number(installmentNumber),
    installments_amount: Number(installmentsAmount),
    installment_value: getMoneyFormatToNumber(installmentValue),
    additional_description: additionalDescription || undefined,
    description,
    cost_center_value: Number(amount),
    id_center_cost_name: costCenter,
    id_center_cost_nature: nature,
    id_center_cost_type: type,
    value_type: checked ? 'Porcentagem' : 'Valor',
    subsidiary_id: unit
  }

  if (!isCnpj) {
    payload.course_id = course
  }

  if (subcategory) {
    payload.id_center_cost_subcategory = subcategory
  }

  return payload
}
