import theme from 'theme/designTokens'
import { UNITS } from 'services/constants'
import { roundScore } from 'services/helpers'
import generateDocDefinitionsBase from '../shared/BasePage'
import {
  DOCUMENT_TITLE,
  MAX_SUBJECTS_PER_PAGE,
  DOCUMENT_TITLE_PARTIAL_REPORT
} from './constants'
import { utilsPDF } from '../shared/utils'
import buildSignature from './Signature'
import { buildClassInfo } from './ClassInfo'
import buildTable from './Table'

const { chunkArray } = utilsPDF

function getCourseWorkload(unit, teoric, intern, pratical) {
  const isLikeNovoTec = [UNITS.NOVOTEC.name, UNITS.MS.name].includes(unit)

  if (isLikeNovoTec) return teoric

  if ((intern && pratical) || intern) return teoric + intern

  if (pratical) return teoric + pratical

  return teoric
}

function getFinalResult({ student, gradeDetails }) {
  if (!student.active) {
    return student.occurrence_type_name
  }

  const approved = gradeDetails.every(
    (gradeDetail) => gradeDetail.student.approved
  )

  return approved ? 'Aprovado' : 'Reprovado'
}

function handleSubjectsMS({ subjects }) {
  const moduleInRoman = {
    1: 'I',
    2: 'II',
    3: 'III',
    4: 'IV'
  }
  const subjetcsByModule = subjects.reduce((acc, currentSubject) => {
    const currentModule = acc[currentSubject.module]

    if (currentModule) {
      currentModule.beginAt =
        new Date(currentSubject.beginAt).getTime() >
        new Date(currentModule.beginAt).getTime()
          ? currentModule.beginAt
          : currentSubject.beginAt

      currentModule.endAt =
        new Date(currentSubject.endAt).getTime() <
        new Date(currentModule.endAt).getTime()
          ? currentModule.endAt
          : currentSubject.endAt

      currentModule.subjectId.push(currentSubject.subjectId)
      currentModule.subjects.push(currentSubject)

      currentModule.workload += currentSubject.workload
    } else {
      acc[currentSubject.module] = {
        beginAt: currentSubject.beginAt,
        endAt: currentSubject.endAt,
        module: currentSubject.module,
        name: `Unidade Curricular ${moduleInRoman[currentSubject.module]}`,
        subjectId: [currentSubject.subjectId],
        workload: currentSubject.workload,
        subjects: [currentSubject]
      }
    }

    return acc
  }, {})
  const subjectList = Object.values(subjetcsByModule)
  const subjectsMap = new Map(subjectList.map((el) => [el.module, el]))

  return {
    chunks: chunkArray(subjectList, MAX_SUBJECTS_PER_PAGE),
    subjectsMap
  }
}

function handleSubjects({ subjects, classInfo }) {
  switch (classInfo.unit) {
    case UNITS.MS.name: {
      return handleSubjectsMS({ subjects })
    }

    default:
      return {
        chunks: chunkArray(subjects, MAX_SUBJECTS_PER_PAGE),
        subjectsMap: new Map()
      }
  }
}

function getGradeDetailsChunkedMS({ gradeDetails, subjectsMap, classInfo }) {
  const gradeDetailsByModules = gradeDetails.reduce(
    (acc, currentGradeDetail, index, array) => {
      const { discipline, frequency, grade, student } = currentGradeDetail
      const moduleNumber = currentGradeDetail.discipline.module
      const currentModule = acc[moduleNumber]

      if (currentModule) {
        currentModule.student = {
          ...currentModule.student,
          active: currentModule.student.active ? student.active : false,
          occurrence_type_name:
            currentModule.student.occurrence_type_name ??
            student.occurrence_type_name,
          has_academic_performance:
            currentModule.student.has_academic_performance ??
            student.has_academic_performance,
          academic_performance_grade:
            currentModule.student.academic_performance_grade ??
            student.academic_performance_grade,
          approved: currentModule.student.approved ? student.approved : false
        }

        currentModule.frequency = {
          absence: currentModule.frequency.absence + frequency.absence,
          absenceInPercentage:
            currentModule.frequency.absenceInPercentage +
            frequency.absenceInPercentage,
          approvedByFrequency: currentModule.frequency.approvedByFrequency
            ? frequency.approvedByFrequency
            : false,
          justifiedAbsence:
            currentModule.frequency.justifiedAbsence +
            frequency.justifiedAbsence,
          maximumAbsence:
            currentModule.frequency.maximumAbsence + frequency.maximumAbsence,
          presence: currentModule.frequency.presence + frequency.presence
        }

        currentModule.grade = {
          assessmentGrade:
            currentModule.grade.assessmentGrade + grade.assessmentGrade,
          activitiesGrade:
            currentModule.grade.activitiesGrade + grade.activitiesGrade,
          partialGrade: currentModule.grade.partialGrade + grade.partialGrade,
          recoveryGrade:
            currentModule.grade.recoveryGrade + grade.recoveryGrade,
          finalGrade: currentModule.grade.finalGrade + grade.finalGrade,
          approvedByGrade: false
        }
      } else {
        acc[moduleNumber] = currentGradeDetail
        acc[moduleNumber].discipline = subjectsMap.get(moduleNumber)
      }

      return acc
    },
    {}
  )

  const gradeDetailsList = Object.values(gradeDetailsByModules)

  gradeDetailsList.forEach((el) => {
    const subject = subjectsMap.get(el.discipline.module)
    const amountSubjectsByModule = subject?.subjectId.length
    const finalGrade = Number(
      roundScore(el.grade.finalGrade / amountSubjectsByModule)
    )
    // eslint-disable-next-line no-param-reassign
    el.student.approved = finalGrade >= classInfo.minimumGrade
    // eslint-disable-next-line no-param-reassign
    el.grade.finalGrade = finalGrade
    // eslint-disable-next-line no-param-reassign
    el.frequency.absenceInPercentage /= amountSubjectsByModule
  })

  return {
    chunks: chunkArray(gradeDetailsList, MAX_SUBJECTS_PER_PAGE),
    unchunked: gradeDetailsList
  }
}

function getGradeDetailsChunked({ gradeDetails, classInfo, subjectsMap }) {
  switch (classInfo.unit) {
    case UNITS.MS.name: {
      const gradeDetailsMS = getGradeDetailsChunkedMS({
        gradeDetails,
        subjectsMap,
        classInfo
      })
      return {
        chunks: gradeDetailsMS.chunks,
        unchunkedList: gradeDetailsMS.unchunked
      }
    }

    default:
      return {
        chunks: chunkArray(gradeDetails, MAX_SUBJECTS_PER_PAGE),
        unchunkedList: gradeDetails
      }
  }
}

const buildResultReportContent = ({
  classInfo,
  courseInfo,
  students,
  subjects,
  isPartialReport
}) => {
  const classWorkload = getCourseWorkload(
    classInfo.unit,
    courseInfo.theoreticalWorkload,
    courseInfo.internshipWorkload,
    courseInfo.practicalWorkload
  )

  const { chunks: subjectsChunk, subjectsMap } = handleSubjects({
    subjects,
    classInfo
  })

  const studentWithGradesChunked = students.map(({ student, gradeDetails }) => {
    const gradeDetailsChunks = getGradeDetailsChunked({
      gradeDetails,
      classInfo,
      subjectsMap
    })

    return {
      student: {
        ...student,
        result: getFinalResult({
          student,
          gradeDetails: gradeDetailsChunks.unchunkedList
        })
      },
      gradeDetails: gradeDetailsChunks.chunks
    }
  })

  return subjectsChunk.map((chunk, indexChunk) => [
    buildClassInfo(classInfo),
    ...buildTable({
      subjects: chunk,
      students: studentWithGradesChunked,
      classInfo,
      indexChunk,
      classWorkload,
      isPartialReport
    }),
    buildSignature({
      signatures: classInfo.signatures,
      directorName: classInfo.directorUnitName,
      secretaryUnitName: classInfo.secretaryUnitName,
      schoolName: classInfo.unit,
      city: classInfo.unitCity
    })
  ])
}

export async function ResultReportPDF({ finalReportData, isPartialReport }) {
  const signatureImage1 = finalReportData?.classInfo?.signatures[0]?.image
  const signatureImage2 = finalReportData?.classInfo?.signatures[1]?.image

  const docDefinitionsBase = await generateDocDefinitionsBase({
    definitionsToReplace: {
      styles: {
        tableHeaderCell: {
          fillColor: theme.palette.background.tableHeaderPDF,
          bold: true,
          fontSize: 7
        },
        tableBodyCell: {
          fontSize: 7
        }
      },
      ...(signatureImage1 || signatureImage2
        ? {
            images: {
              ...(signatureImage1
                ? {
                    signature1: {
                      url: finalReportData.classInfo.signatures[0].image,
                      headers: {
                        'Access-Control-Allow-Origin': '*'
                      }
                    }
                  }
                : {}),
              ...(signatureImage2
                ? {
                    signature2: {
                      url: finalReportData.classInfo.signatures[1].image,
                      headers: {
                        'Access-Control-Allow-Origin': '*'
                      }
                    }
                  }
                : {})
            }
          }
        : {}),
      pageBreakBefore: (
        currentNode,
        followingNodesOnPage,
        nodesOnNextPage,
        previousNodesOnPage
      ) => {
        const shouldBreakBefore =
          currentNode?.id === 'signatureInfo' &&
          currentNode.pageNumbers.length > 1

        return shouldBreakBefore
      }
    }
  })

  return docDefinitionsBase({
    documentTitle: isPartialReport
      ? DOCUMENT_TITLE_PARTIAL_REPORT
      : DOCUMENT_TITLE,
    mainContent: buildResultReportContent({
      classInfo: finalReportData.classInfo,
      courseInfo: finalReportData.courseInfo,
      students: finalReportData.students,
      subjects: finalReportData.subjects,
      isPartialReport
    }),
    companyInfo: finalReportData.companyInfo
  })
}
