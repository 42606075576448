import { useEffect, useState } from 'react'
import {
  IconButton,
  Switch,
  Tooltip,
  Typography,
  InputAdornment
} from '@mui/material'
import TAG_MANAGER from 'tagManager'
import { ModalConfirm, Select } from 'components/molecules'
import { maxValuePercentage } from '../../constants'
import { totalBalance } from '../../services'
import * as Styled from './style'
import { handleSubCategory, handleTypes } from './services'

const CostCenter = ({
  values,
  touched,
  errors,
  setFieldValue,
  setValues,
  cancelAction,
  confirmAction = () => {},
  listNature,
  listUnits,
  listCostCenter,
  isDisabledFields
}) => {
  const [listType, setListType] = useState([])
  const [listSubcategory, setListSubcategory] = useState([])
  const [open, setOpen] = useState(false)

  const onHandleModalClear = () => {
    const defaultValues = {
      unit: '',
      costCenter: '',
      type: '',
      subcategory: '',
      nature: '',
      checked: false,
      amount: ''
    }
    setValues({ ...values, ...defaultValues })
    setOpen(false)
    confirmAction()
  }

  const onHandleChecked = (e) => {
    const { checked, name } = e.target
    setFieldValue(name, checked)
    setFieldValue('amount', '')
  }

  const handleCenterCost = (e) => {
    const selectedCostCenter = e.target.value
    setValues({
      ...values,
      subcategory: '',
      type: '',
      costCenter: selectedCostCenter
    })

    if (selectedCostCenter) {
      handleTypes({ idCenterCost: selectedCostCenter, setListType })
    }
  }

  const handleType = (e) => {
    const selectedType = e.target.value
    setValues({
      ...values,
      type: selectedType,
      subcategory: ''
    })

    if (selectedType) {
      handleSubCategory({ idType: selectedType, setListSubcategory })
    }
  }

  const handleAmount = (e) => {
    const value = values?.checked
      ? Math.min(parseInt(e.target.value, 10), maxValuePercentage)
      : e.target.value

    setFieldValue('amount', value)
  }

  function mountOptions(items, labelKey, valueKey) {
    const options = items?.map((item) => ({
      label: item[labelKey],
      value: item[valueKey]
    }))

    return options ?? []
  }

  useEffect(() => {
    if (values?.costCenter && values?.type) {
      handleTypes({ idCenterCost: values?.costCenter, setListType })
      handleSubCategory({ idType: values?.type, setListSubcategory })
    }
  }, [values?.costCenter && values?.type])

  return (
    <>
      <Styled.Header>
        <Select
          id="unit"
          name="unit"
          value={values?.unit || ''}
          label="Unidade"
          required
          optionsList={mountOptions(listUnits, 'name', 'id')}
          onChange={({ target }) => setFieldValue('unit', target.value)}
          disabled={Boolean(isDisabledFields)}
          helperText={touched.unit && errors.unit}
          error={Boolean(touched.unit && errors.unit)}
        />
        <Select
          id="costCenter"
          name="costCenter"
          value={values?.costCenter || ''}
          label="Centro de Custo"
          required
          optionsList={mountOptions(listCostCenter, 'name', 'id')}
          onChange={(event) => handleCenterCost(event)}
          disabled={Boolean(isDisabledFields)}
          helperText={touched.costCenter && errors.costCenter}
          error={Boolean(touched.costCenter && errors.costCenter)}
        />
        <Select
          id="type"
          name="type"
          value={values?.type || ''}
          label="Tipo"
          required
          optionsList={mountOptions(listType, 'name', 'id_cc4')}
          onChange={(event) => handleType(event)}
          disabled={!values?.costCenter || isDisabledFields}
          helperText={touched.type && errors.type}
          error={Boolean(touched.type && errors.type)}
        />
        <Select
          id="subcategory"
          name="subcategory"
          value={values?.subcategory || ''}
          label="Sub-categoria"
          required
          optionsList={mountOptions(listSubcategory, 'name', 'id_cc5')}
          onChange={({ target }) => setFieldValue('subcategory', target.value)}
          disabled={!values?.type || isDisabledFields}
          helperText={touched.subcategory && errors.subcategory}
          error={Boolean(touched.subcategory && errors.subcategory)}
        />
        <Select
          id="nature"
          name="nature"
          value={values?.nature || ''}
          label="Natureza"
          required
          optionsList={mountOptions(listNature, 'name', 'id')}
          onChange={({ target }) => setFieldValue('nature', target.value)}
          disabled={Boolean(isDisabledFields)}
          helperText={touched.nature && errors.nature}
          error={Boolean(touched.nature && errors.nature)}
        />
        <Styled.BoxValue>
          <Tooltip title="Habilitar para porcentagem" placement="top">
            <Styled.BoxTooltip>
              <Styled.Switch>
                <Switch
                  name="checked"
                  checked={values?.checked || false}
                  onClick={(e) => onHandleChecked(e)}
                  disabled={Boolean(isDisabledFields)}
                />

                <Typography>%</Typography>
              </Styled.Switch>
            </Styled.BoxTooltip>
          </Tooltip>

          <Styled.TextField
            fullWidth
            name="amount"
            variant="outlined"
            label="Valor"
            required
            onChange={(e) => handleAmount(e)}
            type="number"
            disabled={Boolean(isDisabledFields)}
            InputProps={{
              value: values?.amount || '',
              startAdornment: (
                <InputAdornment position="start">
                  {values?.checked ? '%' : 'R$'}
                </InputAdornment>
              )
            }}
            helperText={touched.amount && errors.amount}
            error={Boolean(touched.amount && errors.amount)}
          />
          <Tooltip title="Limpar" placement="top">
            <Styled.BoxTooltip>
              <IconButton
                className={TAG_MANAGER.finance_btn_excludes_title_cost_center}
                disableRipple
                onClick={() => setOpen(true)}
                disabled={Boolean(isDisabledFields)}
              >
                <Styled.DeleteIcon />
              </IconButton>
            </Styled.BoxTooltip>
          </Tooltip>
        </Styled.BoxValue>
      </Styled.Header>
      {values?.paymentMethod && values?.amountPaid && (
        <Styled.InfoFooter>
          <Typography variant="subtitle2">SALDO</Typography>
          <Styled.Total variant="subtitle2">
            {totalBalance(values)}
          </Styled.Total>
        </Styled.InfoFooter>
      )}

      <ModalConfirm
        open={open}
        size="medium"
        handleClose={(event) =>
          cancelAction ? cancelAction(event) : setOpen(false)
        }
        textButtonNotConfirm="Não"
        textButtonConfirm="Sim"
        onClickNotConfirm={(event) =>
          cancelAction ? cancelAction(event) : setOpen(false)
        }
        onClickConfirm={() => onHandleModalClear(setValues)}
        colorButtonConfirm="error"
        icon={<Styled.IconWarningAmber />}
        title="Limpar Centro de Custo"
        message="Você tem certeza que deseja limpar este centro de custo?"
      />
    </>
  )
}
export default CostCenter
