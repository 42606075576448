import { useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { httpPost } from 'services/api/http'

const useAuth = () => {
  const [token, setToken] = useState('')
  const [isLoading, setIsLoadind] = useState(false)
  const [hasError, setHasError] = useState('')
  const { userType, userBoundId, refreshToken } = useParams()

  const getValidToken = useCallback(async () => {
    if (isLoading) return

    setIsLoadind(true)

    try {
      const {
        data: { data }
      } = await httpPost(
        `${process.env.REACT_APP_API_AUTH}/account/refresh-token`,
        { refreshToken, applicationId: 1 }
      )
      const { token: newToken } = data

      setToken(newToken)
    } catch (error) {
      setHasError(error)
      console.error('Erro ao obter o token:', error)
    } finally {
      setIsLoadind(false)
    }
  }, [isLoading])

  const loginAuthToken = async ({
    token: authToken,
    userBoundId: boundId,
    userType: type
  }) => {
    const response = await httpPost(
      `${process.env.REACT_APP_API_HOST}/v1/login-auth-api`,
      { token: authToken, userBoundId: boundId, userType: type }
    )
    return response
  }

  return {
    token,
    userType,
    userBoundId,
    hasError,
    getValidToken,
    loginAuthToken
  }
}

export default useAuth
