import { ROUTE } from 'services/constants'

export const initialValues = (params) => ({
  account: '',
  typeOfPayment: '',
  documentDate: new Date(),
  dueDate: null,
  installmentNumber: '',
  installmentsAmount: '',
  installmentValue: '',
  description: '',
  additionalDescription: '',
  hasCostCenter: false,
  checked: false,
  isCnpj: params?.isCnpj === 'true'
})

export const breadcrumbsItems = [
  {
    path: ROUTE.ADMIN_FINANCIAL_BILLS_RECEIVE,
    label: 'Financeiro'
  },
  {
    path: ROUTE.ADMIN_FINANCIAL_BILLS_RECEIVE,
    label: 'Contas a Receber'
  },
  {
    path: ROUTE.ADMIN_FINANCIAL_BREAKDOWN,
    label: '<b>Novo Título a Receber</b>'
  }
]

export const paymentTypesOptions = [
  {
    label: 'Recorrência',
    value: 'RECURRENCE'
  },
  {
    label: 'Boleto',
    value: 'DEFAULT'
  }
]

export const feedbackMessage = 'Houve um erro ao salvar. Tente novamente.'

export const successMessage = 'Título salvo com sucesso'

export const textTitleModalConfirm = 'Cancelar'

export const textMessageModalConfirm =
  'Deseja cancelar a operação? Você perderá as alterações que ainda não foram salvas.'
