import React from 'react'
import { TableRow } from '@mui/material'
import { useCurrentUser } from 'services/hooks'
import { indexInicial, finalGrade, emptyValue } from './constants'
import * as Styled from './style'

const TableHeader = ({
  row,
  labelId,
  index,
  dropouts,
  headCells,
  hiddeColumns
}) => {
  const { disciplineId } = useCurrentUser()
  const showAsterisk =
    row.linked && !disciplineId.includes(row.origin_discipline)

  return (
    <Styled.TableBody>
      <TableRow
        hover
        role="checkbox"
        id={labelId}
        tabIndex={-indexInicial}
        key={row.register}
      >
        {dropouts &&
          headCells.map((headCell) => {
            const value = row[headCell.id] || emptyValue

            if (
              Array.isArray(hiddeColumns) &&
              hiddeColumns.includes(headCell.id)
            ) {
              return null
            }

            if (headCell.id === 'name') {
              return (
                <Styled.TableCell $fieldSize="40%" $fiedlName>
                  {`${value}${showAsterisk ? '*' : ''}` || '-'}
                  {!!row.has_academic_performance && (
                    <Styled.TagAcademicPerformances>
                      Aluno com Aproveitamento de Estudos na Disciplina
                    </Styled.TagAcademicPerformances>
                  )}
                </Styled.TableCell>
              )
            }

            if (headCell.id === 'index') {
              return (
                <Styled.TableCell $fieldSize="6%">
                  {index + indexInicial}
                </Styled.TableCell>
              )
            }

            if (headCell.id === 'final_grade') {
              return (
                <Styled.TableCellScore
                  key={headCell.id}
                  $colorFinalGrade={
                    row.approved ?? row.final_grade > finalGrade
                  }
                >
                  {value}
                </Styled.TableCellScore>
              )
            }

            return (
              <Styled.TableCell key={headCell.id} $fieldSize="18%">
                {row[headCell.id] || emptyValue}
              </Styled.TableCell>
            )
          })}
        {!dropouts && <Styled.TableCell $fieldSize="65%" />}
      </TableRow>
    </Styled.TableBody>
  )
}

export default TableHeader
